import { ThemeNames } from "../../contexts/application/constants"
import * as abrdn from "../abrdn/abrdn/abrdn"
import * as abrdnBalmoral from "../abrdn/balmoral/balmoral"
import * as abrdnCapital from "../abrdn/capital/capital"
import * as abrdngibbs from "../abrdn/gibbs/gibbs"
import * as abrdnLudlow from "../abrdn/ludlow/ludlow"
import * as abrdnSheridan from "../abrdn/sheridan/sheridan"
import * as abrdnSuccesion from "../abrdn/succession/succession"
import * as abrdnVanilla from "../abrdn/vanilla/vanilla"
import * as amadeus from "../amadeus/amadeus"
import * as coop from "../co-op/coop"
import * as lifestage from "../lifestage/lifestage"

export function getTheme(theme: string) {
  switch (theme.toUpperCase()) {
    case "CO_OP":
      return coop
    case "AMADEUS":
      return amadeus
    case "ABRDN":
      return abrdn
    case "ABRDN_VANILLA":
      return abrdnVanilla
    case "ABRDN_BALMORAL":
      return abrdnBalmoral
    case "ABRDN_CAPITAL":
      return abrdnCapital
    case "ABRDN_LUDLOW":
      return abrdnLudlow
    case "ABRDN_GIBBS":
      return abrdngibbs
    case "ABRDN_SUCCESSION":
      return abrdnSuccesion
    case "ABRDN_SHERIDAN":
      return abrdnSheridan
    case ThemeNames.LIFESTAGE:
      return lifestage
    default:
      return coop
  }
}
