/* istanbul ignore file */
// This file is only used from the GlobalStyle component and does not need to have code coverage. If this fails we will noticed it in storybook or when the app runs.

import { ThemeNames } from "../../contexts/application/constants"
import abrdn from "../../design-tokens/themes/abrdn/abrdn"
import abrdnCapital from "../../design-tokens/themes/abrdn/abrdnCapital"
import abrdnVanilla from "../../design-tokens/themes/abrdn/abrdnVanilla"
import abrdnBalmoral from "../../design-tokens/themes/abrdn/balmoral"
import abrdngibbs from "../../design-tokens/themes/abrdn/gibbs"
import abrdnLudlow from "../../design-tokens/themes/abrdn/ludlow"
import abrdnSheridan from "../../design-tokens/themes/abrdn/sheridan"
import abrdnSuccesion from "../../design-tokens/themes/abrdn/succession"
import amadeus from "../../design-tokens/themes/amadeus"
import aviva from "../../design-tokens/themes/aviva"
import barclays from "../../design-tokens/themes/barclays"
import coop from "../../design-tokens/themes/coop"
import fortCirrus from "../../design-tokens/themes/fortCirrus"
import interactiveInvestor from "../../design-tokens/themes/interactiveInvestor"
import jamesHay from "../../design-tokens/themes/jamesHay"
import legado from "../../design-tokens/themes/legado"
import lifestage from "../../design-tokens/themes/lifestage"
import moneyhub from "../../design-tokens/themes/moneyhub"
import moneyhubExl from "../../design-tokens/themes/moneyhubExl"
import oneLegacy from "../../design-tokens/themes/oneLegacy"
import oneX from "../../design-tokens/themes/oneX"
import quilter from "../../design-tokens/themes/quilter"
import scottishWidows from "../../design-tokens/themes/scottishWidows"
import succession from "../../design-tokens/themes/succession"
import theFamilyElephant from "../../design-tokens/themes/theFamilyElephant"

export function getTheme(theme: string) {
  switch (theme.toUpperCase()) {
    case "SCOTTISH_WIDOWS":
      return scottishWidows
    case "ONE_X":
      return oneX
    case "LEGADO":
      return legado
    case "JAMES_HAY":
      return jamesHay
    case "SUCCESSION":
      return succession
    case "AVIVA":
      return aviva
    case "ABRDN":
      return abrdn
    case "ABRDN_VANILLA":
      return abrdnVanilla
    case "ABRDN_BALMORAL":
      return abrdnBalmoral
    case "ABRDN_LUDLOW":
      return abrdnLudlow
    case "ABRDN_CAPITAL":
      return abrdnCapital
    case "ABRDN_GIBBS":
      return abrdngibbs
    case "ABRDN_SUCCESSION":
      return abrdnSuccesion
    case "ABRDN_SHERIDAN":
      return abrdnSheridan
    case "INTERACTIVE_INVESTOR":
      return interactiveInvestor
    case "BARCLAYS":
      return barclays
    case "MONEYHUB":
      return moneyhub
    case "MONEYHUB_EXL":
      return moneyhubExl
    case "QUILTER":
      return quilter
    case "THE_FAMILY_ELEPHANT":
      return theFamilyElephant
    case "FORT_CIRRUS":
      return fortCirrus
    case "CO_OP":
      return coop
    case "ONE_LEGACY":
      return oneLegacy
    case "AMADEUS":
      return amadeus
    case ThemeNames.LIFESTAGE:
      return lifestage
    default:
      return legado
  }
}
